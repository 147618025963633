import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import NoPostsFeed from '../../../common/containers/no-posts-feed';
import PostListProGallery from '../../../common/components/post-list-pro-gallery';
import { SECTION_HOMEPAGE, SECTION_POST_LIST } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import { POST_LIST_WIDGET_DEFAULTS } from '@wix/communities-blog-client-common/dist/src/constants/post-list-widget-defaults';
import { getFeedPosts } from '../../../common/selectors/post-selectors';
import { getIsFeedPostsLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getIsFeedPostsLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';

const PostList = ({ posts, isLoading, isLoaded, componentId }) => {
  const hasPosts = posts.length;

  if (isLoaded && !hasPosts) {
    return <NoPostsFeed />;
  }

  return (
    <PostListProGallery
      layoutName={POST_LIST_WIDGET_DEFAULTS.layoutName}
      layoutType={POST_LIST_WIDGET_DEFAULTS.layoutType}
      entityCount={POST_LIST_WIDGET_DEFAULTS.entityCount}
      layoutDefaults={POST_LIST_WIDGET_DEFAULTS}
      domId={componentId}
      allPosts={posts}
      currentPagePosts={posts}
      isLoading={isLoading}
      showCreatePostAction={false}
      section={SECTION_POST_LIST}
      hideThreeDots={true}
      hideAuthorBadge={true}
    />
  );
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  return {
    posts: getFeedPosts(state),
    isLoading: getIsFeedPostsLoading(state),
    isLoaded: getIsFeedPostsLoaded(state),
    componentId: host.id,
  };
};

export default connect(mapRuntimeToProps)(PostList);
